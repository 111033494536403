<template>
  <div class="login ly-wrap">
    <div class="top-wrap">
      <div class="left"></div>
      <div class="right"></div>
      <div class="top"></div>
    </div>
    <div class="content">
      <div class="item">
        <div class="icon"></div>
        <input type="text" v-model="form.username" placeholder="请输入用户名/手机号码">
      </div>
      <div class="item">
        <div class="icon lock"></div>
        <input type="password" v-model="form.password" placeholder="请输入密码">
      </div>
      <div class="remember" style="margin-bottom: 0.4rem">
        <div class="ly-check">
          <div :class="isRemember?'checked':'check'" @click="isRemember=!isRemember"></div>
          <div class="txt">记住密码</div>
        </div>
        <a @click="goWay('/user/mes')" v-track-event="'忘记密码, 进入'">忘记密码</a>
      </div>
      <mt-button size="large" class="ly-btn" type="primary" @click="handleLogin" v-track-event="'登陆按钮, 操作'">登 录
      </mt-button>
    </div>

    <div class="tips">
      <div :class="isAgree?'checked':''" class="check" @click="isAgree=!isAgree"></div>
      同意 <a href="http://app.yunsxk.com/app/#/user/agreement" v-track-event="'用户协议, 跳转'">用户协议</a> 和 <a
        href="http://app.yunsxk.com/app/#/user/privacy" v-track-event="'隐私政策, 跳转'">隐私政策</a></div>

    <div class="down">
      <div class="left"></div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>

import {login, postAppPush} from '../api/api.js'
import {setAccessToken, setUserInfo, setUserLogin, getUserLogin, removeUserLogin} from '../utils/auth'
import {Toast} from 'mint-ui';
import {isMobile} from "../utils/util";

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      isRemember: true,
      isAgree: true,
      form: {
        username: '',
        password: ''
      }
    }
  },
  created() {
    this.loadBase()
  },
  methods: {
    loadBase() {
      //  判断是否有保存密码
      let cookieData = getUserLogin()
      if (cookieData) {
        this.isRemember = cookieData.isRemember || false
      }
      if (cookieData.username && cookieData.username != '') {
        this.form = {
          username: cookieData.username,
          password: cookieData.password
        }
      }
    },
    handleLogin() {
      if (!this.isAgree) {
        Toast("请先勾选同意用户协议和隐私政策！")
        return
      }
      const body = Object.assign({'isRemember': this.isRemember}, this.form)
      if (this.isRemember) {
        setUserLogin(body)
      } else {
        removeUserLogin()
      }
      if (!this.form.username) {
        Toast("请先输入用户名或手机号码！")
        return;
      }
      if (!this.form.password) {
        Toast("请先输入密码！")
        return;
      }

      login(this.form).then(res => {
        setAccessToken(res.data.result.token)
        setUserInfo(res.data.result.userInfo)
        this.$store.dispatch('USER_INFO', res.data.result.userInfo)
        if (isMobile()) {
          // eslint-disable-next-line
          plus.push.getClientInfoAsync(
              info => {
                console.log(info.clientid);
                const param = {
                  userName: res.data.result.userInfo.username,
                  cid: info.clientid
                };
                postAppPush(param).then(res => {
                  this.$router.push({path: '/'})
                });
              },
              err => {
                Toast('获取用户CID失败')
              }
          );
        } else {
          this.$router.push({path: '/'})
        }

      })
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
}
</script>

<style scoped lang="less">
.login {
  width: 100%;
  height: 100%;
  background: #ddf1ff;
  position: relative;

  .top-wrap {
    width: 7.5rem;
    height: 5.29rem;
    position: relative;

    .left {
      width: 7.5rem;
      height: 1.58rem;
      background: url("../assets/img/login/tp-lt.png") no-repeat center;
      background-size: 7.5rem;
      position: absolute;
      bottom: 0;
      animation: upMove 3s infinite;
      animation-direction: alternate;
    }

    .right {
      width: 7.5rem;
      height: 1.96rem;
      background: url("../assets/img/login/tp-rt.png") no-repeat center;
      background-size: 7.5rem;
      position: absolute;
      bottom: 0;
      right: 0;
      animation: downMove 3s infinite;
      animation-direction: alternate;
    }

    .top {
      width: 7.5rem;
      height: 5.29rem;
      background: url("../assets/img/login/tp-bg.gif") no-repeat center;
      background-size: 7.5rem;
      z-index: 10;
      position: relative;
    }
  }

  .content {
    width: 5.89rem;
    height: auto;
    padding-top: 0.5rem;
    margin: 0 auto;

    .item {
      width: 100%;
      height: 0.88rem;
      background: #FFFFFF;
      border: 1px solid #0088ff;
      //box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.18);
      border-radius: 10px;
      margin: 0 auto 0.6rem;
      display: flex;

      .icon {
        width: 1rem;
        height: 0.88rem;
        background: url("../assets/img/login/i-user.png") no-repeat center;
        background-size: 0.28rem;
      }

      .lock {
        background: url("../assets/img/login/i-lock.png") no-repeat center;
        background-size: 0.28rem;
      }

      input {
        width: 4.6rem;
        border: none;
        outline: none;
        overflow: hidden;
        font-size: 0.3rem;
        color: #999;
      }
    }

    .remember {
      display: flex;

      .ly-check {
        flex: 1;
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;

        .checked {
          width: 0.3rem;
          height: 0.3rem;
          background: url("../assets/img/login/checked.png") no-repeat center;
          background-size: 0.28rem;
          position: relative;
          top: 0.05rem;
        }

        .check {
          width: 0.3rem;
          height: 0.3rem;
          background: url("../assets/img/login/check.png") no-repeat center;
          background-size: 0.28rem;
          position: relative;
          top: 0.05rem;
        }

        .txt {
          flex: 1;
          text-align: left;
          text-indent: 1em;
        }
      }

      a {
        width: 1.5rem;
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0088FF;
      }
    }

    .ly-btn {
      height: 0.88rem;
      margin: 0 auto;
      background-color: #0088FF;
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: bold;
      border-radius: 0.1rem;
      box-shadow: 0px 10px 13px 0px rgba(0, 136, 255, 0.34);
      //letter-spacing: 1em;
    }
  }

  .tips {
    width: 100%;
    height: 0.6rem;
    line-height: 0.6rem;
    font-size: 0.2rem;
    text-align: center;
    padding-top: 2.4rem;

    .check {
      display: inline-block;
      width: 0.3rem;
      height: 0.3rem;
      background: url("../assets/img/login/check.png") no-repeat center;
      background-size: 0.28rem;
      position: relative;
      top: 0.05rem;
    }

    .checked {
      background: url("../assets/img/login/checked.png") no-repeat center;
      background-size: 0.28rem;
    }

    a {
      text-decoration: none;
      color: #0088FF;
      padding: 0 0.1rem;
    }
  }

  .down {
    width: 100%;
    height: 1rem;

    .left {
      width: 6.07rem;
      height: 0.96rem;
      background: url("../assets/img/login/bt-lf.png") no-repeat center;
      background-size: 6.07rem;
      position: fixed;
      bottom: -0.1rem;
      animation: upMove 3s infinite;
      animation-direction: alternate;
    }

    .right {
      width: 7.5rem;
      height: 0.81rem;
      background: url("../assets/img/login/bt-rt.png") no-repeat center;
      background-size: 7.5rem;
      position: fixed;
      bottom: 0;
      animation: downMove 3s infinite;
      animation-direction: alternate;
    }
  }

}


@keyframes upMove {
  0% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(-0.1rem);
  }
}

@keyframes downMove {
  0% {
    transform: translateY(0rem);
  }
  100% {
    transform: translateY(0.15rem);
  }
}

</style>

